//import 'typeface-ibm-plex-sans'
//import 'typeface-ibm-plex-serif'

const text = {
	primary: 'rgba(255, 255, 255, 1)',
	secondary: 'rgba(255, 255, 255, 0.8)',
	light: 'rgba(255, 255, 255, 0.6)',
	disabled: 'rgba(255, 255, 255, 0.4)',
	contrast: 'rgba(16, 21, 46, 1)',
}

const secondary = {
	lightest: 'rgba(0, 0, 0, 1)',
	lighter: 'rgba(25, 24, 26, 1)',
	light: 'rgba(16, 21, 46, 1)',
	main: 'rgba(16, 21, 46, 0.8)',
	dark: 'rgba(16, 21, 46, 0.6)',
	darkMedium: 'rgba(16, 21, 46, 0.4)',
	darker: 'rgba(16, 21, 46, 0.12)',
	contrastInverseText: text.primary,
}

const theme = {
	typography: {
		fontFamily: '"Inter", sans-serif',
		h1: {
			color: secondary.dark,
		},
		h2: {
			color: secondary.dark,
		},
		h3: {
			color: secondary.dark,
		},
		h4: {
			color: secondary.dark,
		},
		h5: {
			color: secondary.dark,
		},
		text: {
			color: secondary.dark,
		},
		body: {
			color: secondary.light,
		},
		small: {
			color: secondary.light,
		},
		comment: {
			color: secondary.lighter,
		},
		inside: {
			color: secondary.lighter,
		},
		// TODO este typography es añadido
		// en un futuro estaría bien comentar con el diseñador para que se integre en el sistema de diseño
		menu: {
			color: secondary.dark,
		},
	},
	palette: {
		mode: 'dark',
		text,
		corporative: {
			lighter: 'rgba(36, 56, 98, 0.6)',
			light: 'rgba(36, 56, 98, 0.8)',
			main: 'rgba(36, 56, 98, 1)',
			contrastText: text.primary,
		},
		primary: {
			lighter: 'rgba(36, 56, 98, 1)',
			light: 'rgba(240, 241, 242, 1)',
			main: 'rgba(225, 227, 231, 1)',
			dark: 'rgba(208, 210, 214, 1)',
			darker: 'rgba(163, 163, 163, 1)',
		},
		secondary,
		neutral: {
			lighter: 'rgba(163, 163, 163, 1)',
			light: 'rgba(208, 210, 214, 1)',
			main: 'rgba(238, 237, 240, 1)',
			medium: 'rgba(225, 227, 231, 1)',
			dark: 'rgba(240, 241, 242, 1)',
			darker: 'rgba(255, 255, 255, 1)',
			contrastText: text.primary,
			contrastInverseText: text.contrast,
		},
		error: {
			main: 'rgba(213, 0, 50, 1)',
		},
		info: {
			main: 'rgba(0, 111, 214, 1)',
		},
		success: {
			main: 'rgba(0, 153, 138, 1)',
		},
		warning: {
			main: 'rgba(247, 189, 0, 1)',
		},
	},
	shadows: [
		'none',
		'0px 1px 3px 1px rgba(14, 35, 64, 0.12)',
		'0px 2px 4px rgba(14, 35, 64, 0.12)',
		'0px 4px 4px rgba(0, 0, 0, 0.25)',
		'0px 3px 10px rgba(16, 21, 46, 0.24)',
		'0px 30px 84px rgba(19, 10, 46, 0.08), 0px 8px 32px rgba(19, 10, 46, 0.07), 0px 3px 14px rgba(19, 10, 46, 0.03), 0px 1px 3px rgba(19, 10, 46, 0.13)',
		'0px 30px 84px rgba(19, 10, 46, 0.08), 0px 8px 32px rgba(19, 10, 46, 0.07), 0px 3px 14px rgba(19, 10, 46, 0.03), 0px 1px 3px rgba(19, 10, 46, 0.13)',
		'0px 30px 84px rgba(19, 10, 46, 0.08), 0px 8px 32px rgba(19, 10, 46, 0.07), 0px 3px 14px rgba(19, 10, 46, 0.03), 0px 1px 3px rgba(19, 10, 46, 0.13)',
		'0px 30px 84px rgba(19, 10, 46, 0.08), 0px 8px 32px rgba(19, 10, 46, 0.07), 0px 3px 14px rgba(19, 10, 46, 0.03), 0px 1px 3px rgba(19, 10, 46, 0.13)',
		'0px 30px 84px rgba(19, 10, 46, 0.08), 0px 8px 32px rgba(19, 10, 46, 0.07), 0px 3px 14px rgba(19, 10, 46, 0.03), 0px 1px 3px rgba(19, 10, 46, 0.13)',
		'0px 4px 10px 0px rgba(0, 0, 0, 0.10)',
	],
}

export default theme
