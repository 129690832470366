import { Typography, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { CatalogEntity } from "../../services/catalog";
import { GroupedEntities } from "./actions/retrieveAgreements";
import { Link } from "@backstage/core-components";
import { BackstageTheme } from "@backstage/theme";
import { useNavigate } from "react-router-dom";

type EntitiesPanelProps = {
  values: GroupedEntities;
  onSelect: (value: CatalogEntity) => void;
};

export const GroupedEntitiesList = ({ values, onSelect }: EntitiesPanelProps) => {
  const [selected, setSelected] = useState<CatalogEntity>();
  const navigate = useNavigate();

  const handleClick = (value: CatalogEntity) => {
    onSelect(value);
    setSelected(value);
  };

  const docUrl = (entity: CatalogEntity) =>
    `/agreements/${entity.metadata.namespace}/${entity.kind}/${entity.metadata.name}`;
  
  useEffect(() => {
    const firstGroup = Object.values(values)[0];
    if (!firstGroup || firstGroup.length === 0) return;
    const firstEntity = firstGroup[0];

    onSelect(firstEntity);
    setSelected(firstEntity);
    navigate(docUrl(firstEntity));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, onSelect]);

 

  const useStyles = makeStyles<BackstageTheme>((theme) => ({
    selected: {
      "background-color": `#f860e5`,// `#e7214f`rojo (allfunds),// `#e2a30a` amarillo(Ferrovial), 
    },
    "agreement-element": {
      padding: "4px 8px",
    },
    links: {
      color: `${theme.palette.textContrast}`,
    },
    agreements: {
      "list-style": "none",
      padding: "0px",
      "background-color": `${theme.palette.background.default}`,
    },
  }));
  const classes = useStyles();

  return (
    <>
      {Object.entries(values).map(([groupName, entities]) => (
        <div key={groupName}>
          <h2>{groupName}</h2>
          <ul className={classes.agreements}>
            {entities.map((entity) => (
              <li
                key={entity.metadata.name}
                className={`${classes["agreement-element"]} ${
                  selected?.metadata.name === entity.metadata.name ? classes.selected : ""
                }`}
              >
                <Link to={docUrl(entity)} onClick={() => handleClick(entity)}>
                  <span className={classes.links}>{entity.metadata.title}</span>{" "}
                  <Typography variant="caption" color="textSecondary">
                    ({entity.metadata.name})
                  </Typography>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </>
  );
};
