import { Content, PageWithHeader } from "@backstage/core-components";
import { useApi } from "@backstage/core-plugin-api";
import { catalogApiRef } from "@backstage/plugin-catalog-react";
import { Tab } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import React, { useEffect, useMemo, useState } from "react";
import { CatalogService } from "../../services/catalog";
import { GroupedEntitiesPanel } from "./GroupedEntitiesPanel";
import { GroupedEntities, RetrieveAgreements } from "./actions/retrieveAgreements";

export const AgreementsList = ({ children }: { children?: React.ReactNode }) => {
  const BY_PHASE = "phase";
  const BY_ASPECT = "aspect";
  const BY_ROLE = "role";
  const [tabSelected, setTabSelected] = useState<string>(BY_PHASE);

  const catalogApi = useApi(catalogApiRef);
  const catalogService = useMemo(() => new CatalogService(catalogApi), [catalogApi]);
  const retrieveAgreementsActions = useMemo(
    () => new RetrieveAgreements(catalogService),
    [catalogService]
  );
  const [agreementsByPhase, setAgreementsByPhase] = useState<GroupedEntities>({});
  const [agreementsByAspect, setAgreementsByAspect] = useState<GroupedEntities>({});
  const [agreementsByRole, setAgreementsByRole] = useState<GroupedEntities>({});

  useEffect(() => {
    const retrieveAgreements = async () => {
      const groupedAgreementsByPhase: GroupedEntities = await retrieveAgreementsActions.byPhase();
      setAgreementsByPhase(groupedAgreementsByPhase);

      const groupedAgreementsByAspect: GroupedEntities = await retrieveAgreementsActions.byAspect();
      setAgreementsByAspect(groupedAgreementsByAspect);

      const groupedAgreementsByRole: GroupedEntities = await retrieveAgreementsActions.byRole();
      setAgreementsByRole(groupedAgreementsByRole);
    };

    retrieveAgreements();
  }, [retrieveAgreementsActions]);

  const title = "Agreements";

  return (
    <PageWithHeader title={title} themeId="home">
      <Content>
        <TabContext value={tabSelected}>
          <TabList
            onChange={(_, tab) => {
              setTabSelected(tab);
            }}
            indicatorColor="primary"
          >
            <Tab label="By phase" value={BY_PHASE} />
            <Tab label="By aspect" value={BY_ASPECT} />
            <Tab label="By role" value={BY_ROLE} />
          </TabList>
          <TabPanel value={BY_PHASE}>
            <GroupedEntitiesPanel values={agreementsByPhase}>{children}</GroupedEntitiesPanel>
          </TabPanel>
          <TabPanel value={BY_ASPECT}>
            <GroupedEntitiesPanel values={agreementsByAspect}>{children}</GroupedEntitiesPanel>
          </TabPanel>
          <TabPanel value={BY_ROLE}>
            <GroupedEntitiesPanel values={agreementsByRole}>{children}</GroupedEntitiesPanel>
          </TabPanel>
        </TabContext>
      </Content>
    </PageWithHeader>
  );
};
