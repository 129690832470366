import { Entity } from "@backstage/catalog-model";
import { Link } from "@backstage/core-components";
import { EntityProvider } from "@backstage/plugin-catalog-react";
import { Card, CardContent, Grid, makeStyles } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import React, { useState } from "react";
import { GroupedEntitiesList } from "./GroupedEntitiesList";
import "./GroupedEntitiesPanel.css";
import { GroupedEntities } from "./actions/retrieveAgreements";
import Alert from "@material-ui/lab/Alert";
import { Language } from "../../language/en";

type EntitiesPanelProps = {
  values: GroupedEntities;
  children: React.ReactNode;
};

const useStyles = makeStyles(() => ({
  content: {
    position: "relative",
  },
  maximize: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    position: "absolute",
    right: "8px",
    top: "8px",
  },
}));

export const GroupedEntitiesPanel = ({ values, children }: EntitiesPanelProps) => {
  const [selectedAgreement, setSelectedAgreement] = useState<Entity | undefined>();
  const docUrl = (entity: Entity) =>
    `/docs/${entity.metadata.namespace}/${entity.kind}/${entity.metadata.name}`;
  const classes = useStyles();
  const hasValues = Object.keys(values).length > 0;

  return (
    <>
      {!hasValues && (<Alert severity="warning">{Language.noAgreements}</Alert>)}
      {hasValues && (<Grid container>
        <Grid item xs={4}>
          <GroupedEntitiesList values={values} onSelect={setSelectedAgreement} />
        </Grid>
        <Grid item xs={8}>
          {selectedAgreement && (
            <Card>
              <CardContent className={classes.content}>
                <Link
                  to={docUrl(selectedAgreement)}
                  target="_blank"
                  className={classes.maximize}
                  aria-label="Open in new tab"
                >
                  Open in new tab
                  <OpenInNewIcon />
                </Link>
                <EntityProvider entity={selectedAgreement}>{children}</EntityProvider>
              </CardContent>
            </Card>
          )}
        </Grid>
      </Grid>)}
    </>
  );
};

