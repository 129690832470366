export const Language = {
  noAgreements: "There are no agreements registered. Contact the administrator to configure the agreement repository.",
  noConfig: "This item is not configured. Please contact to the administrator to solve it.",
  home: {
    title: "What is DevSecOps Portal?",
    description: "DevSecOps Portal is a meeting point for developers and product managers that offers resources and tools to help delivery teams find everything they need to streamline the DevSecOps and Software Development life cycle.",
  },
  areas: {
    title: "Who are these transversal areas and how do they contribute?",
    description: "In the initial phases, the product manager will inform the transversal areas about the new initiative so that they are in charge of assigning and studying their involvement as appropriate. This team will be maintained to the extent possible throughout the initiative and development phases."
  }
}