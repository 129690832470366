import { CatalogEntity, CatalogService } from "../../../services/catalog";

export class RetrieveAgreements {
  constructor(private readonly catalogService: CatalogService) {}

  public async byPhase(): Promise<GroupedEntities> {
    const agreements = await this.catalogService.retrieveAgreements();
    const phases = await this.catalogService.retrievePhases();
    return this.groupEntitiesByRelation(agreements, phases);
  }

  public async byAspect(): Promise<GroupedEntities> {
    const agreements = await this.catalogService.retrieveAgreements();
    const aspects = await this.catalogService.retrieveAspects();
    return this.groupEntitiesByRelation(agreements, aspects);
  }

  public async byRole(): Promise<GroupedEntities> {
    const agreements = await this.catalogService.retrieveAgreements();
    const roles = await this.catalogService.retrieveRoles();
    return this.groupEntitiesByRelation(agreements, roles);
  }

  private groupEntitiesByRelation(
    entities: CatalogEntity[],
    entitiesGroup: CatalogEntity[]
  ): GroupedEntities {
    const grouped: GroupedEntities = {};
    const groupsName: Record<string, string> = this.mapByTitle(entitiesGroup);

    entities.forEach((agreement) => {
      agreement.relations?.forEach((relation) => {
        const groupName = groupsName[relation.target.name];
        if (groupName) {
          grouped[groupName] = grouped[groupName] ?? [];
          grouped[groupName].push(agreement);
        }
      });
    });

    return grouped;
  }

  private mapByTitle(entities: CatalogEntity[]) {
    return entities.reduce(
      (acum: Record<string, string>, entity: CatalogEntity): Record<string, string> => {
        acum[entity.metadata.name] = entity.metadata.title || entity.metadata.name;
        return acum;
      },
      {}
    );
  }
}

export type GroupedEntities = { [key: string]: CatalogEntity[] };
