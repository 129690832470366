import React from 'react';

import MyCustomIcon from './logo/icon.png';


const LogoIcon = () => {
  return (
    <img 
      src={MyCustomIcon} 
      alt="DevSecOps-Platform" // Add an alt attribute for accessibility
      style={{
        width: '25px'
      }}
    />
  );
};

export default LogoIcon;
