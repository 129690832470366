//import numeral from 'numeral'
import { styled } from '@mui/material/styles'

export const buildStyles = (style) => (Component) => styled(Component)(style)

export const pxToRem = (size, fontSize = 14, htmlFontSize = 16) => {
	const coef = fontSize / 14

	return `${(size / htmlFontSize) * coef}rem`
}

export const remToPx = (size, fontSize = 14, htmlFontSize = 16) => {
	const rem = parseFloat(size.replace('rem', ''))
	const coef = fontSize / 14

	return (rem / coef) * htmlFontSize
}

export const pxToLineHeight = (size, fontSize = 14) => size / fontSize

export const getColor = (key, palette) => {
	if (!key) return null
	const [color, variant] = key.split('.')
	const paletteColor = palette[color] || {}
	return paletteColor[variant] || paletteColor?.main || color
}

export const percentageToColumns = (percentage = 100, columns = 12) => {
	return Math.round((percentage * columns) / 100)
}

export const changeNameSize = (value) => {
	let xs = null
	let sm = null
	let md = null
	let lg = null
	let xl = null

	if (typeof value === 'object') {
		if ('xsmall' in value) {
			xs = value.xsmall
		}
		if ('small' in value) {
			sm = value.small
		}
		if ('medium' in value) {
			md = value.medium
		}
		if ('large' in value) {
			lg = value.large
		}
		if ('xlarge' in value) {
			xl = value.xlarge
		}
	} else {
		xs = sm = md = lg = xl = value
	}

	return {
		xs,
		sm,
		md,
		lg,
		xl,
	}
}

export const decimals = (value) => {
	return value.toFixed(1)
}

export const abbreviateNumber = (data) => {
	const isValidNumber = function (data) {
		return typeof data === 'number'
	}
	return isValidNumber(data) ? numeral(data).format('0a').toUpperCase() : data
}

export const abbreviateMonth = (data) => {
	const isValidDate = function (data) {
		return new Date(data) !== 'Invalid Date' && !isNaN(new Date(data))
	}
	return isValidDate(data)
		? new Date(data).toLocaleString('default', { month: 'short' })
		: data
}

export const mergeSeries = (series) => {
	const merged = []
	series.forEach((serie) => {
		serie.data.forEach(({ key, value, color }) => {
			const sameKey = merged.findIndex((item) => item.key === key)
			if (sameKey !== -1) {
				let obj = {
					...merged[sameKey],
					[serie.name]: value,
					name: serie.name,
				}
				if (color) obj = { ...obj, color }
				merged[sameKey] = obj
			} else {
				let obj = {
					key,
					[serie.name]: value,
					name: serie.name,
				}
				if (color) obj = { ...obj, color }
				merged.push(obj)
			}
		})
	})
	return merged
}

export const getColorString = (color, mode) => {
	const colorToString = (colorProp) =>
		Object.values(colorProp)
			.map((value) => Math.round(value * 100) / 100)
			.join()
	const equivalent = {
		hex: color.hex,
		hsl: color.hsl ? colorToString(color.hsl) : '',
		rgb: color.rgb ? colorToString(color.rgb) : '',
	}
	return equivalent[mode]
}

export const mergeColor = (data = [], theme = {}) => {
	return data.map((item) => ({
		...item,
		fill: theme.palette[item.color].main,
	}))
}

export const uniqueBy = (array, predicate) => {
	const cb = typeof predicate === 'function' ? predicate : (o) => o[predicate]

	return [
		...array
			.reduce((map, item) => {
				const key =
					item === null || item === undefined ? item : cb(item)

				map.has(key) || map.set(key, item)

				return map
			}, new Map())
			.values(),
	]
}

export const getUniqueTicks = (data, formatter) => {
	const ticks = uniqueBy(data, ({ key }) => formatter(key))

	return ticks.map(({ key }) => key)
}
