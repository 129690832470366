//import 'typeface-ibm-plex-sans'
//import 'typeface-ibm-plex-serif'

import { pxToRem, pxToLineHeight } from '../utils'

const text = {
	primary: 'rgba(14, 35, 64, 1)',
	secondary: 'rgba(14, 35, 64, 0.8)',
	light: 'rgba(14, 35, 64, 0.6)',
	disabled: 'rgba(14, 35, 64, 0.4)',
	contrast: 'rgba(36, 56, 98, 1)',
}

const orange = {
	lighter: 'rgba(244, 108, 29, 0.2)',
	light: 'rgba(244, 108, 29, 0.5)',
	main: 'rgba(244, 108, 29, 1)',
	contrastText: text.contrast,
}

const red = {
	lighter: 'rgba(231, 33, 79, 0.2)',
	light: 'rgba(231, 33, 79, 0.5)',
	main: 'rgba(231, 33, 79, 1)',
	contrastText: text.contrast,
}

const green = {
	lighter: 'rgba(14, 143, 97, 0.2)',
	light: 'rgba(14, 143, 97, 0.5)',
	main: 'rgba(14, 143, 97, 1)',
	contrastText: text.contrast,
}

const secondary = {
	lightest: 'rgba(14, 35, 64, 0.12)',
	lighter: 'rgba(14, 35, 64, 0.4)',
	light: 'rgba(14, 35, 64, 0.6)',
	main: 'rgba(14, 35, 64, 0.8)',
	dark: 'rgba(14, 35, 64, 1)',
	darkMedium: 'rgba(30, 58, 97, 1)',
	darker: 'rgba(0, 0, 0, 1)',
	contrastInverseText: text.primary,
}

const neutral = {
	lighter: 'rgba(255, 255, 255, 1)',
	light: 'rgba(241, 241, 243, 1)',
	main: 'rgba(227, 228, 232, 1)',
	medium: 'rgba(217, 218, 222, 1)',
	dark: 'rgba(187, 188, 188, 1)',
	darker: 'rgba(187, 188, 188, 1)',
	contrastText: text.primary,
	contrastInverseText: text.contrast,
}

const theme = {
	spacing: 12,
	shape: {
		borderRadius: 2,
	},
	typography: {
		fontFamily: '"Inter", sans-serif',
		fontSize: 13,
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 500,
		fontWeightBold: 600,
		h1: {
			fontFamily: '"Inter", sans-serif',
			fontSize: pxToRem(48),
			fontWeight: 700,
			lineHeight: pxToLineHeight(62.4, 48),
			color: secondary.dark,
		},
		h2: {
			fontFamily: '"Inter", sans-serif',
			fontSize: pxToRem(36),
			fontWeight: 700,
			lineHeight: pxToLineHeight(41.6, 36),
			color: secondary.dark,
		},
		h3: {
			fontFamily: '"Inter", sans-serif',
			fontSize: pxToRem(28),
			fontWeight: 700,
			lineHeight: pxToLineHeight(36.4, 28),
			color: secondary.dark,
		},
		h4: {
			fontFamily: '"Inter", sans-serif',
			fontSize: pxToRem(20),
			fontWeight: 600,
			lineHeight: pxToLineHeight(31.2, 20),
			color: secondary.dark,
		},
		h5: {
			fontFamily: '"Inter", sans-serif',
			fontSize: pxToRem(18),
			fontWeight: 600,
			lineHeight: pxToLineHeight(20.8, 18),
			color: secondary.dark,
		},
		text: {
			fontFamily: '"Inter", sans-serif',
			fontSize: pxToRem(18),
			fontWeight: 400,
			lineHeight: pxToLineHeight(23.4, 18),
			color: secondary.dark,
		},
		body: {
			fontFamily: '"Inter", sans-serif',
			fontSize: pxToRem(16),
			fontWeight: 400,
			lineHeight: pxToLineHeight(20.8, 16),
			color: secondary.light,
		},
		small: {
			fontFamily: '"Inter", sans-serif',
			fontSize: pxToRem(14),
			fontWeight: 400,
			lineHeight: pxToLineHeight(16, 13),
			color: secondary.light,
		},
		comment: {
			fontFamily: '"Inter", sans-serif',
			fontSize: pxToRem(12),
			fontWeight: 400,
			lineHeight: pxToLineHeight(14.3, 11),
			color: secondary.lighter,
		},
		inside: {
			fontFamily: '"Inter", sans-serif',
			fontSize: pxToRem(10),
			fontWeight: 400,
			lineHeight: pxToLineHeight(11.7, 9),
			color: secondary.lighter,
		},
	},
	palette: {
		mode: 'light',
		text,
		corporative: {
			lighter: 'rgba(36, 56, 98, 0.6)',
			light: 'rgba(36, 56, 98, 0.8)',
			main: 'rgba(36, 56, 98, 1)',
			contrastText: text.contrast,
		},
		primary: {
			lighter: 'rgba(244, 159, 108, 0.06)',
			light: 'rgba(244, 159, 108, 0.12)',
			main: 'rgba(244, 159, 108, 1)',
			dark: orange.main,
			darker: 'rgba(238, 126, 57, 1)',
			gradientStart: 'rgba(244, 159, 108, 0.34)',
			gradientEnd: 'rgba(244, 159, 108, 0)',
			contrastText: text.contrast,
		},
		secondary,
		neutral,
		menu: {
			lighter: 'rgba(255, 255, 255, 0.04)',
			dark: secondary.dark,
			light: neutral.light,
		},
		error: {
			main: red.main,
		},
		info: {
			main: 'rgba(0, 111, 214, 1)',
		},
		success: {
			main: green.main,
		},
		warning: {
			main: 'rgba(255, 208, 124, 1)',
			contrastText: text.contrast,
		},
		// Charts
		red,
		purple: {
			lighter: 'rgba(194, 44, 116, 0.2)',
			light: 'rgba(194, 44, 116, 0.5)',
			main: 'rgba(194, 44, 116, 1)',
			contrastText: text.contrast,
		},
		turquoise: {
			lighter: 'rgba(83, 173, 152, 0.2)',
			light: 'rgba(83, 173, 152, 0.5)',
			main: 'rgba(83, 173, 152, 1)',
			contrastText: text.contrast,
		},
		blue: {
			lightest: 'rgba(68, 124, 203, 0.08)',
			lighter: 'rgba(68, 124, 203, 0.2)',
			light: 'rgba(68, 124, 203, 0.5)',
			main: 'rgba(68, 124, 203, 1)',
			contrastText: text.contrast,
		},
		water: {
			lighter: 'rgba(34, 211, 160, 0.2)',
			light: 'rgba(34, 211, 160, 0.5)',
			main: 'rgba(34, 211, 160, 1)',
			contrastText: text.contrast,
		},
		green,

		lemon: {
			lighter: 'rgba(255, 208, 124, 0.2)',
			light: 'rgba(255, 208, 124, 0.5)',
			main: 'rgba(255, 208, 124, 1)',
			contrastText: text.contrast,
		},
		orange,
		// pink: {
		// 	lighter: 'rgba(211, 61, 208, 0.2)',
		// 	light: 'rgba(211, 61, 208, 0.5)',
		// 	main: 'rgba(211, 61, 208, 1)',
		// 	contrastText: text.contrast,
		// },
		// lime: {
		// 	lightest: 'rgba(175, 226, 124, 0.08)',
		// 	lighter: 'rgba(175, 226, 124, 0.17)',
		// 	light: 'rgba(175, 226, 124, 0.5)',
		// 	main: 'rgba(175, 226, 124, 1)',
		// 	contrastText: text.contrast,
		// },
		// dark_blue: {
		// 	lightest: 'rgba(55, 91, 141, 0.08)',
		// 	lighter: 'rgba(55, 91, 141, 0.2)',
		// 	light: 'rgba(55, 91, 141, 0.5)',
		// 	main: 'rgba(55, 91, 141, 1)',
		// 	contrastText: text.contrast,
		// },
	},
	shadows: [
		'none',
		'0px 1px 3px 1px rgba(14, 35, 64, 0.12)',
		'0px 2px 4px rgba(14, 35, 64, 0.12)',
		'0px 4px 4px rgba(0, 0, 0, 0.25)',
		'0px 3px 10px rgba(14, 35, 64, 0.24)',
		'0px 30px 84px rgba(19, 10, 46, 0.08), 0px 8px 32px rgba(19, 10, 46, 0.07), 0px 3px 14px rgba(19, 10, 46, 0.03), 0px 1px 3px rgba(19, 10, 46, 0.13)',
		'0px 30px 84px rgba(19, 10, 46, 0.08), 0px 8px 32px rgba(19, 10, 46, 0.07), 0px 3px 14px rgba(19, 10, 46, 0.03), 0px 1px 3px rgba(19, 10, 46, 0.13)',
		'0px 30px 84px rgba(19, 10, 46, 0.08), 0px 8px 32px rgba(19, 10, 46, 0.07), 0px 3px 14px rgba(19, 10, 46, 0.03), 0px 1px 3px rgba(19, 10, 46, 0.13)',
		'0px 30px 84px rgba(19, 10, 46, 0.08), 0px 8px 32px rgba(19, 10, 46, 0.07), 0px 3px 14px rgba(19, 10, 46, 0.03), 0px 1px 3px rgba(19, 10, 46, 0.13)',
		'0px 30px 84px rgba(19, 10, 46, 0.08), 0px 8px 32px rgba(19, 10, 46, 0.07), 0px 3px 14px rgba(19, 10, 46, 0.03), 0px 1px 3px rgba(19, 10, 46, 0.13)',
		'0px 4px 10px 0px rgba(0, 0, 0, 0.10)',
	],
}

export default theme
