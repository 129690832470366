import { Entity, EntityRelation } from "@backstage/catalog-model";
import { CatalogApi } from "@backstage/plugin-catalog-react";

export class CatalogService {
  constructor(private readonly catalogApi: CatalogApi) {}

  public async retrieveAgreements(): Promise<CatalogEntity[]> {
    return this.retrieveByKind("Agreement");
  }

  public async retrievePhases(): Promise<CatalogEntity[]> {
    return this.retrieveByKind("Phase");
  }

  public async retrieveAspects(): Promise<CatalogEntity[]> {
    return this.retrieveByKind("Aspect");
  }

  public async retrieveRoles(): Promise<CatalogEntity[]> {
    const groups = await this.retrieveByKind("Group");
    const roles = groups.filter((group) => group.spec?.type === "dependency");
    return roles;
  }

  private async retrieveByKind(kind: string): Promise<CatalogEntity[]> {
    const result = await this.catalogApi.getEntities({ filter: [{ kind }] });
    return result.items as unknown as CatalogEntity[];
  }
}

export type CatalogEntity = Omit<Entity, "relations"> & {
  relations: [
    EntityRelation & {
      target: {
        kind: string;
        name: string;
      };
    }
  ];
};
