import {
  createUnifiedTheme,
  palettes,
  UnifiedTheme,
  createBaseThemeOptions,
  UnifiedThemeOptions
} from "@backstage/theme";
import themeConstants from "./constants"

export class DarkTheme {

  public static readonly theme = (): UnifiedTheme => this.createTheme();

  private static createTheme = (): UnifiedTheme => {
    const theme: UnifiedTheme = createUnifiedTheme({
      ...createBaseThemeOptions({
        palette:palettes.dark
      }),
      fontFamily: themeConstants.typography.fontFamily,
      defaultPageTheme: 'home',
      components: this.customComponentStyles()
    });
    return theme;
  };

  private static customComponentStyles = (): UnifiedThemeOptions["components"] => {
    const customStyles: UnifiedThemeOptions["components"] = {
      BackstageHeader: {
        styleOverrides: {
          header: () => ({
            backgroundImage: 'none',
            backgroundColor: themeConstants.palette.corporative.main
          })
        },
      },
      BackstageSidebar: {
        styleOverrides: {
          drawer: () => ({
            backgroundImage: 'none',
            backgroundColor: themeConstants.palette.corporative.main
          })
        },
        
        },
    };
    return customStyles;
  };


}
